let Env = "production";
let baseUrl = "";
let frontUrl = "";
let contractCreator = "";
let secretOrKey = "";
let icoContract = "";
let bscurl = "";

if (Env == "local") {
  frontUrl = "http://localhost:3000/";
  baseUrl = "http://localhost:3518/";
  secretOrKey = "3TAXElQb9UnwkBv1RFb47w==";
  icoContract = "0xBccDE0f9fEC5E07ab0B390Ea13365692f87DDAF3";
  contractCreator = "0xdC6A32D60002274A16A4C1E93784429D4F7D1C6a";
  bscurl = "https://testnet.bscscan.com/"
}
if (Env == "production") {
  frontUrl = 'https://techs.network/';
  baseUrl = 'https://api.techs.network/';
  secretOrKey = "3TAXElQb9UnwkBv1RFb47w==";
  icoContract = "0x9c4af6387dDe442b841210Cd70A257fBB31bD383";
  contractCreator = "0x373F886904048975034488320E37d171E092C135";
  bscurl = "https://bscscan.com/"
}

module.exports = {
  Front_URL: frontUrl,
  baseUrl: baseUrl,
  secretOrKey:secretOrKey,
  icoContract: icoContract,
  contractCreator:contractCreator,
  bscurl: bscurl
};
