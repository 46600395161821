import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import {
    GET_ERRORS,
    SET_CURRENT_USER,
    FORGOT,
    USER_LOADING
} from "./types";
import keys from "./config";
const url = keys.baseUrl;


export const registerUser = (userData, history) => dispatch => {
    axios
        .post(url+"api/user-add", userData)
        .then(res => {
            history.push("/login")
        })
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};

export const loginUser = userData => dispatch => {
    axios
        .post(url+"api/login", userData)
        .then(res => {
            console.log(res.data,"ressAdminnnn");
            const { token } = res.data;
            localStorage.setItem("adminToken", token);
            setAuthToken(token);
            const decoded = jwt_decode(token);
            console.log(decoded,"decocodeeee")
            dispatch(setCurrentUser(decoded));
            console.log(dispatch,"dispatch")
        })
        .catch(err =>{
            console.log(err,"errr")
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            })
        }
           
        );
};
export const forgotUser = (forgotData,history) => dispatch => {
  axios
      .post(url+"api/forgot", forgotData)
      .then(res => history.push("/login"))
      // .catch(err =>
      //     dispatch({
      //         type: GET_ERRORS,
      //         payload: err.response.data
      //     })
      // );
 };

export const setCurrentUser = decoded => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded
    };
};

export const setUserLoading = () => {
    return {
        type: USER_LOADING
    };
};

export const logoutUser = () => dispatch => {
    localStorage.removeItem("adminToken");
    setAuthToken(false);
    dispatch(setCurrentUser({}));
};
